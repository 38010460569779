import { Routes, Route } from 'react-router-dom';
import './App.css';
import { Home } from './pages/Home';
import { Footer } from './components/Footer';
import { Navbar } from './components/Navbar';
import { Tiers } from './pages/Tiers';
import { Cashout } from './pages/Cashout';
import { Datdrop } from './pages/Datdrop';
import { Competition } from './components/Competition';
import { NotFound } from './pages/NotFound';
import { Empiredrop } from './pages/Empiredrop';
import { NewTiers } from './pages/NewTiers';
import { lazy, Suspense } from 'react';
import Loader from './components/Loader';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EmpiredropDeposits = lazy(() => import('./pages/EmpiredropDeposits'));
const CS2BigBattles = lazy(() => import('./pages/CS2BigBattles'));

function App() {
  return (
    <>
    <Navbar />
      <div className="App">
        <video autoPlay playsInline muted loop id="bg-video">
          <source src="/STATIC_SHOT.mp4" type="video/mp4" />
        </video>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/oldtiers" element={<Tiers />} />
          <Route path="/tiers" element={<NewTiers />} />
          <Route path="/cashout" element={<Cashout />} />
          <Route path="/datdrop" element={<Datdrop />} />
          <Route path="/cs2big/battles" element={<Suspense fallback={<Loader />}><CS2BigBattles /></Suspense>} />
          <Route path="/empiredrop" element={<Empiredrop />} />
          <Route path="/empiredrop/competition" element={<Competition competitionName="empiredrop-comp" />} />
          <Route path="/empiredrop/deposits" element={<Suspense fallback={<Loader />}><EmpiredropDeposits /></Suspense>} />
          <Route path="/datdrop100" element={<Competition competitionName="datdrop-comp100" />} />
          <Route path="/datdrop500" element={<Competition competitionName="datdrop-comp500" />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
        <ToastContainer />
      </div>
    <Footer />
    </>
  );
}

export default App;
