import axios from 'axios';
import { useState, useEffect } from 'react';

export interface Deposit {
    id: number;
    level: number;
    name: string;
    totalDeposits: number;
    totalRewards: number;
    wagerTotal: number;
}

interface WeeklyDepositsResponse {
    success: boolean;
    results: Deposit[];
    startDate: string;
    endDate: string;
    cachedUntil: string;
}

const useGetWeeklyDeposits = () => {
    const [deposits, setDeposits] = useState<Deposit[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [startDate, setStartDate] = useState<string | null>(null);
    const [endDate, setEndDate] = useState<string | null>(null);
    const [cachedUntil, setCachedUntil] = useState<string | null>(null);

    useEffect(() => {
        const fetchWeeklyDeposits = async () => {
            try {
                const response = await axios.get<WeeklyDepositsResponse>(`${process.env.REACT_APP_STRAPI_URL}/api/csgobig`, {
                    headers: {
                        'Authorization': `Bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
                        'Content-Type': 'application/json'
                    }
                });
                if (response.data.success) {
                    const deposits: Deposit[] = response.data.results.filter((deposit: Deposit) => deposit.totalRewards > 0);
                    deposits.forEach((deposit) => {
                        deposit.totalDeposits = deposit.totalDeposits / 1.69;
                    });
                    setDeposits(deposits);
                    setStartDate(response.data.startDate);
                    setEndDate(response.data.endDate);
                    setCachedUntil(response.data.cachedUntil);
                } else {
                    setError('Failed to fetch data');
                }
            } catch (err: any) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchWeeklyDeposits();
    }, []);

    return { deposits, loading, error, startDate, endDate, cachedUntil };
};

export default useGetWeeklyDeposits;
