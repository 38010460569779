import CountdownTimer from "../components/Countdown";
import Loader from "../components/Loader";
import { useGetConversionRates } from "../hooks/useGetConversionRates";
import {useGetEmpireDropDeposits, EmpireDropDeposit } from "../hooks/useGetEmpireDropDeposits";
import '../static/css/EmpireDrop.css'

const MAX_TICKETS: number = 40;
const USD_PER_TICKET: number = 125;

export const Empiredrop = () => {
    // Hooks for current and previous weeks
    const currentWeek = useGetEmpireDropDeposits();
    const previousWeek = useGetEmpireDropDeposits(true);
    // eslint-disable-next-line
    const { conversionRate, loading: conversionLoading, error: conversionError } = useGetConversionRates();

    if (currentWeek.loading || previousWeek.loading) return <Loader />;
    if (currentWeek.error) return <div>{currentWeek.error}</div>;
    if (previousWeek.error) return <div>{previousWeek.error}</div>;
    if (conversionLoading) return <Loader />;
    if (conversionError) return <div>{conversionError}</div>;

    const renderDeposits = (data: any, title: string) => (
        <div className={`tier ${title.toLowerCase().replace(' ', '-')}`}>
            <h2 className="tier__title">{title}</h2>
            <p className="tier__subtitle">
                {title === "Current Week" ? (
                    <CountdownTimer endDate={data.endDate} />
                ) : (
                    `From ${new Date(data.startDate).toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true })} to ${new Date(data.endDate).toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true })}`
                )}
            </p>
            {/* <p className="tier__bonus">1 ticket per ${USD_PER_TICKET} up to ${USD_PER_TICKET * MAX_TICKETS}</p> */}
            {/* NO Conversion */}
            <p className="tier__bonus">1 ticket per €{USD_PER_TICKET} up to €{USD_PER_TICKET * MAX_TICKETS}</p>
            <table className="tier__deposits-table">
                <thead>
                    <tr>
                        <th>User</th>
                        <th>Wagered</th>
                        <th>Tickets</th>
                    </tr>
                </thead>
                <tbody>
                    {data.deposits?.map((deposit: EmpireDropDeposit) => (
                        <tr key={deposit.user.hash_id}>
                            <td>{deposit.user.name}</td>
                            {/* <td>${(deposit.total * conversionRate / 100).toFixed(2)}</td>
                            <td className="reward">
                                {Math.min(MAX_TICKETS, Math.floor(deposit.total * conversionRate / (USD_PER_TICKET * 100)))}
                            </td> */}
                            {/* No conversion */}
                            <td>€{(deposit.total / 100).toFixed(2)}</td>
                            <td className="reward">
                                {Math.min(MAX_TICKETS, Math.floor(deposit.total / (USD_PER_TICKET * 100)))}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );

    return (
        <section id="empiredrop">
            <div className="container">
                <p>The board will update in <CountdownTimer endDate={currentWeek.cachedUntil!} whenFinished={currentWeek.fetchEmpireDropDeposits} /></p>
                <br />
                <div className="tier__wrapper">
                    {renderDeposits(previousWeek, "Previous Week")}
                    {renderDeposits(currentWeek, "Current Week")}
                </div>
            </div>
        </section>
    );
}
