import { useEffect, useState } from "react";
import axios from 'axios';
import '../static/css/Competition.css';
import Markdown from "react-markdown";

interface CompetitionProps {
    competitionName: string;
}

interface CompetitionInterface {
    title: string;
    info: string;
    image: string;
}

export const Competition = ({ competitionName }: CompetitionProps) => {
    const [comp, setComp] = useState<CompetitionInterface | null>(null);
    
    useEffect(() => {
        const fetchCompetition = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_STRAPI_URL}/api/${competitionName}?populate=*`, {
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_KEY}`
                    }
                })

                const fetchedComp: CompetitionInterface = {
                    title: res.data.data.attributes.title,
                    info: res.data.data.attributes.info,
                    image: process.env.REACT_APP_STRAPI_URL + res.data.data.attributes.image.data.attributes.url
                }
                
                setComp(fetchedComp);
            } catch (error) {
                console.log(error)
            }
        }

        fetchCompetition()
    }, [competitionName])
    return (
        <section className="competition__wrapper">
            <div className="container">
                <div className="competition">
                    <h1>{comp?.title}</h1>
                    <p className="note">
                        <Markdown>
                            {comp?.info}
                        </Markdown>
                    </p>
                    <br />
                    <img src={comp?.image} alt={comp?.title} />
                </div>
            </div>
        </section>  
    )
}