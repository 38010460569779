import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

export interface Deposit {
    id: number;
    level: number;
    name: string;
    totalDeposits: number;
    totalRewards: number;
    wagerTotal: number;
}

interface WeeklyDepositsResponse {
    success: boolean;
    deposits: Deposit[];
    startDate: string;
    endDate: string;
    cachedUntil: string;
}

const useGetTierCompetition = (viewPrevious: boolean = false) => {
    const [deposits, setDeposits] = useState<Deposit[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [startDate, setStartDate] = useState<string | null>(null);
    const [endDate, setEndDate] = useState<string | null>(null);
    const [cachedUntil, setCachedUntil] = useState<string | null>(null);

    const fetchWeeklyDeposits = useCallback(async () => {
        setLoading(true);
        try {
            const endpoint = `${process.env.REACT_APP_STRAPI_URL}/api/csgobig/${viewPrevious ? 'twoweeks/prev' : 'twoweeks'}`;
            const response = await axios.get<WeeklyDepositsResponse>(endpoint, {
                headers: {
                    'Authorization': `Bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
                    'Content-Type': 'application/json'
                }
            });

            const bannedUsersResponse = await axios.get(`${process.env.REACT_APP_STRAPI_URL}/api/banned-users`, {
                headers: {
                    'Authorization': `Bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
                    'Content-Type': 'application/json'
                }
            });
            
            const bannedUsers: string[] = bannedUsersResponse.data.data.map((user: any) => user.attributes.userId);
            
            if (response.data.success) {
                let filteredDeposits = response.data.deposits.filter((deposit: Deposit) => deposit.totalRewards > 0);

                // walk through all banned users
                filteredDeposits = filteredDeposits.filter((deposit: Deposit) => !bannedUsers.includes(deposit.id.toString()));
                filteredDeposits.forEach((deposit) => {
                    // deposit.totalDeposits = deposit.totalRewards / 1.69 / 0.09;
                    deposit.totalDeposits = deposit.totalRewards / 0.035 / 1.69;
                });
                setDeposits(filteredDeposits);
                setStartDate(response.data.startDate);
                setEndDate(response.data.endDate);
                setCachedUntil(response.data.cachedUntil);
            } else {
                setError('Failed to fetch data');
            }
        } catch (err: any) {
            console.error(err);
            setError(err.message);
        } finally {
            setLoading(false);
        }
    }, [viewPrevious]);

    useEffect(() => {
        fetchWeeklyDeposits();
    }, [fetchWeeklyDeposits]);

    return { deposits, loading, error, startDate, endDate, cachedUntil, fetchWeeklyDeposits };
};

export default useGetTierCompetition;