import React, { useState } from 'react';
import { Deposit } from '../hooks/useGetWeeklyDeposits';
import '../static/css/Tiers.css';
import Loader from '../components/Loader';
import useGetRewardsPageInfo from '../hooks/useGetRewardsPageInfo';
import Markdown from 'react-markdown';
import CountdownTimer from '../components/Countdown';
import useGetTierCompetition from '../hooks/useGetTierCompetition';

const MAINTENANCE = false;

const calculateTotalBonuses = (deposits: Deposit[]) => {
    const bonusRates = {
        tier3: 0.07,
        tier2: 0.05,
        tier1: 0.035
    };

    let totalBonus = 0;
    deposits.forEach((deposit: Deposit) => {
        if (deposit.totalDeposits > 5000) {
            totalBonus += deposit.totalDeposits * bonusRates.tier3;
        } else if (deposit.totalDeposits > 2000) {
            totalBonus += deposit.totalDeposits * bonusRates.tier2;
        } else if (deposit.totalDeposits > 200) {
            totalBonus += deposit.totalDeposits * bonusRates.tier1;
        }
    });
    return totalBonus;
};

export const NewTiers = (): JSX.Element => {
    const { deposits, loading, error, endDate, cachedUntil, fetchWeeklyDeposits } = useGetTierCompetition();
    const { deposits: lastWeeksDeposits, startDate: lastWeeksStartDate, endDate: lastWeeksEndDate } = useGetTierCompetition(true);
    const [searchTerm, setSearchTerm] = useState('');
    const { rewardsPageInfo, loading: rewardsPageInfoLoading, error: rewardsPageInfoError } = useGetRewardsPageInfo();

    if (loading || rewardsPageInfoLoading) {
        return <Loader />;
    }

    if (error || rewardsPageInfoError) {
        return <p>{error || rewardsPageInfoError}</p>;
    }

    deposits.sort((a, b) => b.totalDeposits - a.totalDeposits);
    const allDepositsSorted = deposits.sort((a, b) => b.totalDeposits - a.totalDeposits);
    console.log(allDepositsSorted);

    const filteredDeposits = deposits.filter(deposit => deposit.name.toLowerCase().includes(searchTerm.toLowerCase()));
    const tier3 = filteredDeposits.filter((deposit) => deposit.totalDeposits > 5000);
    const tier2 = filteredDeposits.filter((deposit) => deposit.totalDeposits > 2000 && deposit.totalDeposits <= 5000);
    const tier1 = filteredDeposits.filter((deposit) => deposit.totalDeposits > 200 && deposit.totalDeposits <= 2000);

    const handleSearchChange = (event: any) => {
        setSearchTerm(event.target.value);
    };

    const lastWeeksDepositsSorted = lastWeeksDeposits.sort((a, b) => b.totalDeposits - a.totalDeposits);

    const totalLastWeekBonuses = calculateTotalBonuses(lastWeeksDeposits);
    if (MAINTENANCE) {
        return (
            // maintenance
            <>
                <div className="wide__container fade-in maintenance">
                    <h1 className="text-center title fly-top">
                        Maintenance
                    </h1>
                    <p className="text-center">
                        <Markdown>
                            Due to changes in CS2Big affiliate program, we are currently making necessary adjustments to our rewards page. We apologize for the inconvenience and appreciate your patience. We will be back soon!
                            {/* Our rewards page is currently undergoing maintenance. We'll be back soon! */}
                        </Markdown>
                    </p>
                    <div className="last__week__wrapper">
                        <h2 className="text-center title">Last week's rewards</h2>
                        <p className="text-center">From {(new Date(lastWeeksStartDate!)).toLocaleDateString('en-CA', { month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', timeZone: 'est' })} to {(new Date(lastWeeksEndDate!)).toLocaleDateString('en-CA', { month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', timeZone: 'est' })} EST.</p>
                        {/* <p className="text-center">This board is cached and will update in <CountdownTimer endDate={lastWeeksCachedUntil!} /></p> */}
                        <div className="tiers__wrapper">
                            {renderTier("Tier 4", "$4500+ in weekly deposits", 0.12, lastWeeksDepositsSorted.filter((deposit) => deposit.totalDeposits > 4500), true)}
                            {renderTier("Tier 3", "$1500 - $4500 in weekly deposits", 0.09, lastWeeksDepositsSorted.filter((deposit) => deposit.totalDeposits > 1500 && deposit.totalDeposits <= 4500), true)}
                            {renderTier("Tier 2", "$975 - $1500 in weekly deposits", 0.06, lastWeeksDepositsSorted.filter((deposit) => deposit.totalDeposits > 975 && deposit.totalDeposits <= 1500), true)}
                            {renderTier("Tier 1", "$225 - $975 in weekly deposits", 0.042, lastWeeksDepositsSorted.filter((deposit) => deposit.totalDeposits > 225 && deposit.totalDeposits <= 975), true)}
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <section>
            <div className="wide__container fade-in">
                <h1 className="text-center title fly-top">
                    {rewardsPageInfo?.title}
                </h1>
                <h3 className="subtitle text-center">
                    <Markdown>{rewardsPageInfo?.subtitle}</Markdown>
                </h3>
                <h2 className="text-center big__timer">
                    <CountdownTimer endDate={endDate!} />
                </h2>
                <div className="info__squares">
                    <div className="last__week__bonuses">
                        <p>Last week's reward:</p>
                        <span className="reward">{totalLastWeekBonuses.toFixed(2)}c</span>
                    </div>
                </div>
                <div className="info__wrapper">
                    <div className="markdown"><Markdown>{rewardsPageInfo?.info}</Markdown></div>
                    {rewardsPageInfo?.warning && <div className="warning__square">
                            <Markdown>{rewardsPageInfo?.warning}</Markdown>
                    </div>}
                </div>
                <p className="text-light">
                    The board will update in <CountdownTimer endDate={cachedUntil!} whenFinished={fetchWeeklyDeposits}/>
                </p>
                <div className="search__wrapper">
                    <input
                        type="text"
                        className="search-input"
                        placeholder="Search by name..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                </div>
                <div className="tiers__wrapper">
                    {renderTier("Tier 3", "$5000+ in weekly deposits", 0.07, tier3)}
                    {renderTier("Tier 2", "$2000 - $5000 in weekly deposits", 0.05, tier2)}
                    {renderTier("Tier 1", "$200 - $2000 in weekly deposits", 0.035, tier1)}
                </div>
                <div className="search__wrapper">
                    {searchTerm && renderNonTierResults(searchTerm, filteredDeposits, tier1, tier2, tier3)}
                </div>
                <div className="last__week__wrapper">
                    <h2 className="text-center title">Last week's rewards</h2>
                    <p className="text-center">From {(new Date(lastWeeksStartDate!)).toLocaleDateString('en-CA', { month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', timeZone: 'est' })} to {(new Date(lastWeeksEndDate!)).toLocaleDateString('en-CA', { month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', timeZone: 'est' })} EST.</p>
                    {/* <p className="text-center">This board is cached and will update in <CountdownTimer endDate={lastWeeksCachedUntil!} /></p> */}
                    <div className="tiers__wrapper">
                        {renderTier("Tier 3", "$5000+ in weekly deposits", 0.07, lastWeeksDepositsSorted.filter((deposit) => deposit.totalDeposits > 5000), true)}
                        {renderTier("Tier 2", "$2000 - $5000 in weekly deposits", 0.05, lastWeeksDepositsSorted.filter((deposit) => deposit.totalDeposits > 2000 && deposit.totalDeposits <= 5000), true)}
                        {renderTier("Tier 1", "$200 - $2000 in weekly deposits", 0.035, lastWeeksDepositsSorted.filter((deposit) => deposit.totalDeposits > 200 && deposit.totalDeposits <= 2000), true)}
                    </div>
                </div>
            </div>
        </section>
    );

    function renderTier(title: string, subtitle: string, bonus: number, deposits: Deposit[], isLastWeek: boolean = false) {
        return (
            <div className={`tier ${title.toLowerCase().replace(" ", "-")}`}>
                <h2 className="tier__title">{title}</h2>
                <p className="tier__bonus">{(bonus*100).toFixed(2)}% case bonus</p>
                <table className="tier__deposits-table">
                    <thead>
                        <tr>
                            <th>Rank</th>
                            <th>User</th>
                            <th>Reward</th>
                            <th>Deposits</th>
                        </tr>
                    </thead>
                    <tbody>
                        {deposits?.map((deposit: any, index: any) => (
                            <tr key={deposit.id}>
                                {isLastWeek ? 
                                    <td>{lastWeeksDepositsSorted.indexOf(deposit) + 1}</td>
                                    :
                                    <td>{allDepositsSorted.indexOf(deposit) + 1}</td>
                                }
                                <td>{deposit.name}</td>
                                <td className="reward">
                                    {(deposit.totalDeposits * bonus * 1.69).toFixed(2)}c
                                </td>
                                <td>${deposit.totalDeposits.toFixed(2)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }

    function renderNonTierResults(searchTerm: string, deposits: Deposit[], ...tiers: Deposit[][]) {
        const nonTierDeposits = deposits.filter(deposit => !tiers.flat().includes(deposit));
        return nonTierDeposits.length > 0 && (
            <div className="tier non-tier-results">
                <h2 className="tier__title">Other deposits</h2>
                <p className="tier__subtitle">No bonus yet</p>
                <table className="tier__deposits-table">
                    <thead>
                        <tr>
                            <th>Rank</th>
                            <th>User</th>
                            <th>Deposits</th>
                        </tr>
                    </thead>
                    <tbody>
                        {nonTierDeposits?.map((deposit, index) => (
                            <tr key={deposit.id}>
                                <td>{allDepositsSorted.indexOf(deposit) + 1}</td>
                                <td>{deposit.name}</td>
                                <td>${deposit.totalDeposits.toFixed(2)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
}
