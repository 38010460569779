import React, { useEffect } from 'react';
import Loader from "../components/Loader";
import useGetDatdropBattles from "../hooks/useGetDatdropBattles";
import '../static/css/Datdrop.css';
import { io } from 'socket.io-client';

export const Datdrop = (): JSX.Element => {
    const { battles, loading, error } = useGetDatdropBattles();

    useEffect(() => {
        const server = process.env.REACT_APP_STRAPI_URL || 'http://localhost:1337';
        const socket = io(server);

        socket.on('connect', () => {
            console.log('Connected to the server');
        });

        socket.on('disconnect', () => {
            console.log('Disconnected from the server');
        });

        socket.on('VIEWER_COUNT', (viewerCount: number) => {    
            // console.log('viewerCount:', viewerCount);
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    if (loading) {
        return <Loader />;
    }

    if (error) {
        return (
            <section>
                <div className="container">
                    <h1 className="title">Datdrop Battles</h1>
                    <p>There was an error fetching the battles. Please try again later.</p>
                </div>
            </section>
        );
    }

    return (
        <section id="datdrop">
            <div className="container">
                <h1 className="title text-center">Datdrop Battles</h1>
                {battles?.length > 0 && <table className="battles-table">
                    <thead>
                        <tr>
                            <th>URL</th>
                            <th>Value</th>
                            <th>Join requirements</th>
                            <th>Created at</th>
                        </tr>
                    </thead>
                    <tbody>
                        {battles.map((battle, index) => (
                            <tr key={index}>
                                <td><a href={battle.url} target="_blank" rel="noopener noreferrer">{battle.url}</a></td>
                                <td>
                                    {battle.value && <strong>${battle.value?.toFixed(2)}</strong>}
                                </td>
                                <td className="requirement">
                                    {battle.min_deposit && battle.min_deposit_time_value && battle.min_deposit_time_unit && (
                                        <p>
                                            You must have deposited <strong>${battle.min_deposit.toFixed(2)}</strong> using my code within the past <strong>{battle.min_deposit_time_value} {battle.min_deposit_time_unit}</strong> to join this battle.
                                        </p>
                                    )}
                                </td>
                                <td className="text-muted">
                                    {new Date(battle.createdAt).toLocaleString()}
                                </td>
                                    
                            </tr>
                        ))}
                    </tbody>
                </table>}
                {battles?.length === 0 && <p>No battles found.</p>}
            </div>
        </section>
    );
}
