import Calendar from "../components/Calendar"
import { Referrals } from "../components/Referrals"
import Socials from "../components/Socials"
import { YouTube } from "../components/Youtube"

export const Home = (): JSX.Element => {
    return (
        <section>
            <div className="container">
                <Socials />
                <Referrals />
                <YouTube />
                <Calendar />
            </div>
        </section>
    )
}