import { Link } from "react-router-dom"

export const NotFound = () => {
    return (
        <section className="not__found">
            <div className="container">
                <h1>We couldn't find the page you were looking for.</h1>
                <br />
                <Link to="/">Go back to the home page</Link>
            </div>
        </section>
    )
}