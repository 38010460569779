import React, { useState } from 'react';
import useGetWeeklyDeposits, { Deposit } from '../hooks/useGetWeeklyDeposits';
import useGetLastWeeksDeposits from '../hooks/useGetLastWeeksDeposits';
import '../static/css/Tiers.css';
import Loader from '../components/Loader';
import useGetRewardsPageInfo from '../hooks/useGetRewardsPageInfo';
import Markdown from 'react-markdown';
import CountdownTimer from '../components/Countdown';

const calculateTotalBonuses = (deposits: Deposit[]) => {
    const bonusRates = {
        tier4: 0.15 * 1.69,
        tier3: 0.09 * 1.69,
        tier2: 0.06 * 1.69,
        tier1: 0.042 * 1.69
    };

    let totalBonus = 0;
    deposits.forEach((deposit: Deposit) => {
        if (deposit.totalDeposits > 2500) {
            totalBonus += deposit.totalDeposits * bonusRates.tier4;
        } else if (deposit.totalDeposits > 999) {
            totalBonus += deposit.totalDeposits * bonusRates.tier3;
        } else if (deposit.totalDeposits > 650) {
            totalBonus += deposit.totalDeposits * bonusRates.tier2;
        } else if (deposit.totalDeposits > 150) {
            totalBonus += deposit.totalDeposits * bonusRates.tier1;
        }
    });
    return totalBonus;
};

export const Tiers = (): JSX.Element => {
    const { deposits, loading, error, endDate, cachedUntil } = useGetWeeklyDeposits();
    const { deposits: lastWeeksDeposits, startDate: lastWeeksStartDate, endDate: lastWeeksEndDate, cachedUntil: lastWeeksCachedUntil } = useGetLastWeeksDeposits();
    const [searchTerm, setSearchTerm] = useState('');
    const { rewardsPageInfo, loading: rewardsPageInfoLoading, error: rewardsPageInfoError } = useGetRewardsPageInfo();

    if (loading || rewardsPageInfoLoading) {
        return <Loader />;
    }

    if (error || rewardsPageInfoError) {
        return <p>{error || rewardsPageInfoError}</p>;
    }

    deposits.sort((a, b) => b.totalDeposits - a.totalDeposits);
    const allDepositsSorted = deposits.sort((a, b) => b.totalDeposits - a.totalDeposits);

    const filteredDeposits = deposits.filter(deposit => deposit.name.toLowerCase().includes(searchTerm.toLowerCase()));
    const tier4 = filteredDeposits.filter((deposit) => deposit.totalDeposits > 2500);
    const tier3 = filteredDeposits.filter((deposit) => deposit.totalDeposits > 999 && deposit.totalDeposits <= 2500);
    const tier2 = filteredDeposits.filter((deposit) => deposit.totalDeposits > 650 && deposit.totalDeposits <= 999);
    const tier1 = filteredDeposits.filter((deposit) => deposit.totalDeposits > 150 && deposit.totalDeposits <= 650);

    const handleSearchChange = (event: any) => {
        setSearchTerm(event.target.value);
    };

    const lastWeeksDepositsSorted = lastWeeksDeposits.sort((a, b) => b.totalDeposits - a.totalDeposits);

    const totalLastWeekBonuses = calculateTotalBonuses(lastWeeksDeposits);

    return (
        <section>
            <div className="wide__container fade-in">
                <h1 className="text-center title fly-top">
                    {rewardsPageInfo?.title}
                </h1>
                <h3 className="subtitle text-center">
                    <Markdown>{rewardsPageInfo?.subtitle}</Markdown>
                </h3>
                <div className="search__wrapper">
                    <input
                        type="text"
                        className="search-input"
                        placeholder="Search by name..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                </div>
                <div className="last__week__bonuses">
                    <p>Last week's reward:</p>
                    <span className="reward">{totalLastWeekBonuses.toFixed(2)}c</span>
                </div>
                {/* <p>The board resets at {(new Date(endDate!)).toLocaleDateString('en-CA', { month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', timeZone: 'est' })} EST.</p> */}
                <p>The board resets in <CountdownTimer endDate={endDate!} /></p>
                <div className="markdown"><Markdown>{rewardsPageInfo?.info}</Markdown></div>
                <p className="text-light">
                    The board will update in <CountdownTimer endDate={cachedUntil!} />
                </p>
                <div className="tiers__wrapper">
                    {renderTier("Tier 4", "$2500+ in weekly deposits", 0.15, tier4)}
                    {renderTier("Tier 3", "$1000 - $2500 in weekly deposits", 0.09, tier3)}
                    {renderTier("Tier 2", "$650 - $999 in weekly deposits", 0.06, tier2)}
                    {renderTier("Tier 1", "$150 - $650 in weekly deposits", 0.042, tier1)}
                </div>
                <div className="search__wrapper">
                    {searchTerm && renderNonTierResults(searchTerm, filteredDeposits, tier1, tier2, tier3, tier4)}
                </div>
                <div className="last__week__wrapper">
                    <h2 className="text-center title">Last week's rewards</h2>
                    <p className="text-center">From {(new Date(lastWeeksStartDate!)).toLocaleDateString('en-CA', { month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', timeZone: 'est' })} to {(new Date(lastWeeksEndDate!)).toLocaleDateString('en-CA', { month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', timeZone: 'est' })} EST.</p>
                    <p className="text-center">This board is cached and will update in <CountdownTimer endDate={lastWeeksCachedUntil!} /></p>
                    <div className="tiers__wrapper">
                        {renderTier("Tier 4", "$2500+ in weekly deposits", 0.15, lastWeeksDepositsSorted.filter((deposit) => deposit.totalDeposits > 2500), true)}
                        {renderTier("Tier 3", "$1000 - $2500 in weekly deposits", 0.09, lastWeeksDepositsSorted.filter((deposit) => deposit.totalDeposits > 999 && deposit.totalDeposits <= 2500), true)}
                        {renderTier("Tier 2", "$650 - $999 in weekly deposits", 0.06, lastWeeksDepositsSorted.filter((deposit) => deposit.totalDeposits > 649 && deposit.totalDeposits <= 999), true)}
                        {renderTier("Tier 1", "$150 - $650 in weekly deposits", 0.042, lastWeeksDepositsSorted.filter((deposit) => deposit.totalDeposits > 149 && deposit.totalDeposits <= 649), true)}
                    </div>
                </div>
            </div>
        </section>
    );

    function renderTier(title: string, subtitle: string, bonus: number, deposits: Deposit[], isLastWeek: boolean = false) {
        return (
            <div className={`tier ${title.toLowerCase().replace(" ", "-")}`}>
                <h2 className="tier__title">{title}</h2>
                <p className="tier__subtitle">{subtitle}</p>
                <p className="tier__bonus">{(bonus*100).toFixed(2)}% case bonus</p>
                <table className="tier__deposits-table">
                    <thead>
                        <tr>
                            <th>Rank</th>
                            <th>User</th>
                            <th>Reward</th>
                            <th>Deposits</th>
                        </tr>
                    </thead>
                    <tbody>
                        {deposits?.map((deposit: any, index: any) => (
                            <tr key={deposit.id}>
                                {isLastWeek ? 
                                    <td>{lastWeeksDepositsSorted.indexOf(deposit) + 1}</td>
                                    :
                                    <td>{allDepositsSorted.indexOf(deposit) + 1}</td>
                                }
                                <td>{deposit.name}</td>
                                <td className="reward">
                                    {(deposit.totalDeposits * bonus * 1.69).toFixed(2)}c
                                </td>
                                <td>${deposit.totalDeposits.toFixed(2)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }

    function renderNonTierResults(searchTerm: string, deposits: Deposit[], ...tiers: Deposit[][]) {
        const nonTierDeposits = deposits.filter(deposit => !tiers.flat().includes(deposit));
        return nonTierDeposits.length > 0 && (
            <div className="tier non-tier-results">
                <h2 className="tier__title">Other deposits</h2>
                <p className="tier__subtitle">No bonus yet</p>
                <table className="tier__deposits-table">
                    <thead>
                        <tr>
                            <th>Rank</th>
                            <th>User</th>
                            <th>Deposits</th>
                        </tr>
                    </thead>
                    <tbody>
                        {nonTierDeposits?.map((deposit, index) => (
                            <tr key={deposit.id}>
                                <td>{allDepositsSorted.indexOf(deposit) + 1}</td>
                                <td>{deposit.name}</td>
                                <td>${deposit.totalDeposits.toFixed(2)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
}
